<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <h3 class="inBlock">门店角色</h3>
    </div>
    <div class="shopuser">
      <div class="title">
        <p>
          <el-button type="success" round size="mini" @click="addrole"
            >添加角色</el-button
          >
        </p>
        <p style="margin-top: 20px">
          <span>角色名:</span
          ><input
            v-model="form.name"
            placeholder="请输入角色名查询"
            type="text"
          />
        </p>
        <p style="margin-top: 20px">
          <el-radio v-model="form.status" :label="''">全部</el-radio>
          <el-radio v-model="form.status" :label="1">开启</el-radio>
          <el-radio v-model="form.status" :label="2">禁用</el-radio>
          <el-button size="mini" type="primary" @click="shaixuan"
            >筛选</el-button
          >
        </p>
      </div>
      <el-table
        :data="list"
        stripe
        border
        :header-row-style="{ height: '40px' }"
        :row-style="{ height: '50px' }"
        style="width: 60%"
      >
        <el-table-column prop="id" label="角色ID"> </el-table-column>
        <el-table-column prop="name" label="角色名称"> </el-table-column>

        <el-table-column label="创建时间">
          <div slot-scope="scope">
            {{ scope.row.createtime | datefilter }}
          </div>
        </el-table-column>
        <el-table-column prop="status" label="角色状态">
          <div slot-scope="scope">
            <el-tag v-if="scope.row.status == 1" type="success" effect="dark"
              >可用</el-tag
            >
            <el-tag v-else type="info" effect="dark">禁用</el-tag>
            <span
              class="setstatus shou"
              @click="setStatus(scope.row.id, scope.row.status)"
              >修改</span
            >
          </div>
        </el-table-column>
        <el-table-column label="操作">
          <div class="caozuo" slot-scope="scope">
            <div class="btnDel shou" @click="del(scope.row.id)">删除</div>
          </div>
        </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 20px"
        @current-change="crtChg"
        :current-page="form.page"
        layout="prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog title="添加角色" :visible.sync="addDialogshow" width="25%">
      <div class="addroleform">
        <el-form ref="form" :model="addform" label-width="80px">
          <el-form-item label="角色名称:">
            <el-input v-model="addform.name"></el-input>
          </el-form-item>
          <!-- <el-form-item label="上级ID">
            <el-input v-model="addform.parent_id"></el-input>
          </el-form-item> -->
          <el-form-item label="状态">
            <el-radio v-model="addform.status" :label="1">可用</el-radio>
            <el-radio v-model="addform.status" :label="2">禁用</el-radio>
          </el-form-item>
          <el-form-item label="排序">
            <el-input v-model="addform.list_order"></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input v-model="addform.remark"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer">
        <el-button @click="addDialogshow = false">取 消</el-button>
        <el-button type="primary" @click="addroleSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </el-card>

  <!-- 添加角色对话框 -->
</template>

<script>
import { rolelist, delrole, addrole, setRolestatus } from "@/api/api.js";
export default {
  components: {},
  name: "",
  data() {
    return {
      form: {
        name: "",
        status: "",
        page: 1,
        limit: 10,
      },
      addform: {
        id: null, // 角色id
        parent_id: 0, // 父级id
        status: 1, // 状态
        list_order: "", //排序
        name: "", // 角色名称
        remark: "", // 备注
      },
      list: [],
      total: 1,
      addDialogshow: false,
    };
  },
  methods: {
    async setStatus(id, status) {
      this.$confirm("请确定是否修改用户状态?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let sts = status == 1 ? 2 : 1;
          const { data } = await setRolestatus({ id, status: sts });
          if (data.code != 200) return this.$message.error(data.data);
          console.log(data);
          this.list.forEach((item) => {
            if (item.id == id) {
              let index = this.list.indexOf(item);
              this.list[index].status = sts;
            }
          });
          this.getlist();
          this.$message({
            type: "success",
            message: "修改成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消修改",
          });
        });
    },

    async addroleSubmit() {
      const data = await addrole(this.addform);
      this.addDialogshow = false;
      this.getlist();
      console.log(data);
    },

    addrole() {
      this.addDialogshow = true;
    },

    async del(id) {
      const { data } = await delrole({ id });
       this.$confirm("请确定是否删除门店角色?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { data } = await delrole({ id });
          if (data.code != 200) return this.$message.error(data.data);
          console.log(data);
          this.list.forEach((item) => {
            if (item.id == id) {
              let index = this.list.indexOf(item);
              // this.list[index].status = sts;
            }
          });
          this.getlist();
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    crtChg(e) {
      this.page = e;
      this.getlist();
    },

    shaixuan() {
      this.form.page = 1;
      this.form.limit = 10;
      this.getlist();
    },

    async getlist() {
      const { data } = await rolelist(this.form);
      if(data.code != 200) return this.$message.error(data.data)
      this.total = data.data.total;
      this.list = data.data.data;
    },
  },

  created() {
    this.getlist();
  },

};
</script>

<style scoped lang='less'>
.shopuser {
  .title {
    font-size: 13px;
    background-color: #eee;
    padding: 10px;
    padding-top: 20px;
    margin: 5px 0 20px 0;
    p {
      input {
        height: 30px;
        margin-left: 5px;
        font-size: 12px;
      }
    }
  }
  .imgbox {
    width: 30px;
    img {
      width: 100%;
    }
  }
  .addroleform {
    h5 {
      text-align: center;
      font-size: 18px;
      font-weight: normal;
      margin: 10px 0 30px 0;
    }
    .el-form-item {
      .el-input {
        width: 300px;
      }
    }
  }
}
.setstatus {
  margin-left: 10px;
  border-bottom: 1px solid #888;
  display: inline-block;
  &:hover {
    color: #1aad19;
    border-bottom: 1px solid #1aad19;
  }
}
/deep/.el-table-column {
  padding: 0;
}
</style>